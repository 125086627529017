import { $ajaxService } from '@/service/request'
// import { token } from '@/service/token-mock'
// $ajaxService.setHeaderAuthorization(token)

// 设置个性欢迎语接口 https://paas-teamwork.yuque.com/books/share/2123af1d-33bd-47b6-ae01-6b94a3f603dc/pqvgsw#s6G8l
export const setGuideWelcomeMsgs = (params = {}) => {
  return $ajaxService.post(
    `${$ajaxService.baseUrl}api_guide_customer_welcome_msgs/set`,
    params,
    { loading: true, ignoreEmptyParams: true }
  )
}
// 获取个性欢迎语接口 https://paas-teamwork.yuque.com/books/share/2123af1d-33bd-47b6-ae01-6b94a3f603dc/pqvgsw#s6G8l
export const getGuideWelcomeMsgs = (params = {}) => {
  return $ajaxService.post(
    `${$ajaxService.baseUrl}api_guide_customer_welcome_msg/get`,
    params
  )
}
// 删除个性欢迎语接口 https://paas-teamwork.yuque.com/books/share/2123af1d-33bd-47b6-ae01-6b94a3f603dc/pqvgsw#s6G8l
export const deleteGuideWelcomeMsgs = () => {
  return $ajaxService.post(
    `${$ajaxService.baseUrl}api_guide_customer_welcome_msgs/set`,
    {
      send_type: '',
      qw_content: '删除'
    },
    { loading: true }
  )
}
// 添加企微素材添加接口 https://paas-teamwork.yuque.com/books/share/2123af1d-33bd-47b6-ae01-6b94a3f603dc/pqvgsw#s6G8l
export const addQWImageSources = (file, params = {}) => {
  const paramsData = {
    source_name: '客户欢迎语',
    source_type: 1,
    validity_start_time: '2021-1-18 00:00:00',
    validity_end_time: '3021-1-19 00:00:00',
    ...params
  }
  let formData = new FormData()
  for (let [key, value] of Object.entries(paramsData)) {
    formData.append(key, value)
  }
  formData.append('file', file)
  return $ajaxService.post(`${$ajaxService.baseUrl}api_sources/add`, formData)
}

// https://paas-teamwork.yuque.com/docs/share/b2e86bf8-767b-486e-9d8b-f6c807f30436#01237532
// 话术类型
export const getSpeechcraftType = async () => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_speechcraft/get_type'
  )

  // await new Promise((resolve) => setTimeout(resolve, 1000))

  // return [
  //   {
  //     id: 0,
  //     name: 'company',
  //     cname: '公司话术',
  //     setting: false
  //   },
  //   {
  //     id: 1,
  //     name: 'personal',
  //     cname: '个人话术',
  //     setting: true
  //   }
  // ]

  return Array.from(data, (item) => ({
    id: item.id,
    name: item.category,
    cname: item.category_name,
    setting: item.is_edit
  }))
}

// 智能话术列表
export const getAiSpeechcraftType = async (params = {}) => {
  let { page = 1, page_size = 10, chat_id = '', customer_id = '' } = params
  const { data } = await $ajaxService.get(
    `${$ajaxService.baseUrl}guide_get_intelligent_discourse_list?page=${page}&page_size=${page_size}&chat_id=${chat_id}&customer_id=${customer_id}`
  )
  console.log('getAiSpeechcraftType--getAiSpeechcraftType=====>', data)
  // return Array.from(data, (item) => ({
  //   id: item.id,
  //   name: item.category,
  //   cname: item.category_name,
  //   setting: item.is_edit
  // }))
  return data.list
}
// https://paas-teamwork.yuque.com/docs/share/fd34647c-8144-4b3a-ae51-8c40f9209cc3#caa35661
// 话术分组列表
export const getSpeechcraftGroup = async ({ typeId }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_speechcraft/get_group',
    {
      type_id: typeId,
      page: 1,
      page_size: 100
    }
  )

  // await new Promise((resolve) => setTimeout(resolve, 1000))

  // return [
  //   {
  //     id: 3,
  //     name: 'jieshao',
  //     cname: '介绍'
  //   },
  //   {
  //     id: 4,
  //     name: 'gsjies',
  //     cname: '公司介绍'
  //   }
  // ]

  // { open: string, list: [] }

  return Array.from(data, (item) => ({
    id: item.id,
    name: item.category,
    cname: item.category_name
  }))
}

// https://paas-teamwork.yuque.com/docs/share/b2e86bf8-767b-486e-9d8b-f6c807f30436#caa35661
// 话术列表
export const getSpeechcraft = async ({
  page,
  size,
  typeId,
  groupId,
  query
}) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_speechcraft/list',
    {
      type_id: typeId,
      group_id: groupId,
      content: query,
      page,
      page_size: size
    }
  )

  return Array.from(data, (item) => ({
    id: item.id,
    title: item.title,
    introduction: item.introduction,
    content: item.content,
    groupId: item.group_id,
    classId: item.follow_record_class_id
  }))

  // await new Promise((resolve) => setTimeout(resolve, 1000))

  // const start = (page - 1) * size,
  //   data = []

  // if (page < 3) {
  //   for (let i = 1; i <= size; i++) {
  //     data.push({ id: start + i, content: '内容' + i })
  //   }
  // }

  // return data
}

// 新增话术
export const addSpeechcraft = async (item) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_speechcraft/add',
    {
      title: item.title || '话术',
      type_id: item.typeId,
      group_id: item.groupId,
      content: item.content
    }
  )

  return {
    id: data.id,
    title: data.title,
    content: data.content,
    classId: data.follow_record_class_id
  }
}

// 修改话术
export const editSpeechcraft = async (item) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'api_speechcraft/edit',
    {
      id: item.id,
      title: item.title || '',
      content: item.content
    }
  )
}

// 删除话术
export const delSpeechcraft = async (id) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'material_library/del_content',
    { id }
  )
}
