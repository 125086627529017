/* 客户欢迎语 业务逻辑 */
// import { $ajaxService } from '@/service/request'
import {
  getGuideWelcomeMsgs,
  setGuideWelcomeMsgs,
  addQWImageSources
} from '@/service/tool.service'
// 获取欢迎语接口数据后进行业务逻辑处理，数据格式化处理
export const getGuideWelcomeMsgsPre = async () => {
  try {
    const { data } = await getGuideWelcomeMsgs()
    // 业务数据处理，为外部设置默认值
    let newState = {}
    let newForm = {}
    // 已设置过
    if (data && data.create_time) {
      newState.canDelete = true
      const {
        send_type,
        qw_content,
        qw_media_id,
        qw_pic_url,
        qw_link_title,
        qw_link_desc,
        qw_link_url,
        qw_link_picurl
      } = data
      newForm.qw_content = qw_content
      newState.bindImage = send_type === 'IMAGE'
      newState.bindLink = send_type === 'LINK'
      if (newState.bindImage) {
        Object.assign(newForm, {
          qw_media_id,
          qw_pic_url
        })
        if (qw_pic_url) newState.imageList = [{ url: qw_pic_url }]
      } else if (newState.bindLink) {
        Object.assign(newForm, {
          qw_link_title,
          qw_link_desc,
          qw_link_url,
          qw_link_picurl
        })
        if (qw_link_picurl) newState.linkImageList = [{ url: qw_link_picurl }]
      }
    } else {
      // 未设置
      newState.editable = true
    }
    return { newState, newForm }
  } catch (e) {
    return Promise.reject(e)
  }
}
// 根据已有表单提交情况，组装需要提交欢迎的数据格式
export const setGuideWelcomeMsgsPres = (state, form) => {
  const {
    qw_content,
    qw_media_id,
    qw_pic_url,
    qw_link_title,
    qw_link_desc,
    qw_link_picurl,
    qw_link_url
  } = form
  let params = {
    send_type: 'TEXT',
    qw_content
  }
  if (state.bindImage) {
    Object.assign(params, {
      send_type: qw_pic_url ? 'IMAGE' : 'TEXT',
      qw_pic_url,
      qw_media_id
    })
  } else if (state.bindLink) {
    Object.assign(params, {
      send_type: 'LINK',
      qw_link_title,
      qw_link_desc,
      qw_link_picurl,
      qw_link_url
    })
  }
  return setGuideWelcomeMsgs(params)
}
export const addQWImageSourcesPre = async (file) => {
  try {
    file.status = 'uploading'
    file.message = '上传中...'
    const { data } = await addQWImageSources(file.file)
    return data
  } catch (e) {
    console.log('上传失败')
    file.status = 'failed'
    file.message = '上传失败'
    return Promise.reject(e)
  }
}
